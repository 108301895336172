import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from "@mui/material/Typography";


import IOChat from './IOChat';
import IOLogo from './IO_Logo_White.png';
import { ResetHistory } from './auth/user';
import Login from './auth/login';
import Logout from './auth/logout';


const IO_AI_URL = process.env.REACT_APP_IO_AI_URL || 'http://localhost:8081';

const App = () => {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading,  } = useAuth0();
  const [token, setToken] = useState('');
  const [menuAnchor, setMenuAnchor] = useState(null);

  const showAuthDialog = isLoading || !isAuthenticated;

  useEffect(() => {
    async function fetchToken() {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);

      }
      if (isAuthenticated) {
        fetchToken();
      }
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };
   
  return (<>
    <AppBar color="primary">
      <Toolbar>
        <Box flexGrow={1}>
            <img src={IOLogo} width={150} alt="IO Logo" />
        </Box>
      <Box flexGrow={0} display="flex" sx={{textAlign: "right"}}>
        {isAuthenticated && <Box>
            <Button
                variant="outline"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
            >
                <Typography variant="caption">{user.email}</Typography>
            </Button>

            <Menu
                id="menu-appbar"
                anchorEl={menuAnchor}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(menuAnchor)}
                onClose={handleClose}
              >
                <MenuItem sx={{cursor: "default"}}><ResetHistory baseUrl={IO_AI_URL} token={token} /></MenuItem>
                <MenuItem sx={{cursor: "default"}}><Logout/></MenuItem>
              </Menu>
        </Box>}
      </Box>
      </Toolbar>
    </AppBar>

    <Dialog
      open={showAuthDialog}
      transitionDuration={0}
      sx={{mt: "-15vh", textAlign:"center"}}
    >
      <DialogTitle>{isLoading ? "Authenticating" : "Please Authenticate"}</DialogTitle>
      <DialogContent className="nlux-comp-wlc_msg" sx={{padding: 0}}>
          <DialogContentText sx={{margin: 1}}>
            <img className="nlux-comp-avtr" src={'/static/bob_avatar.png'} alt="IO Bob" />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{mx: "auto", mb: 2}}>
            {!isLoading && <Login/>}
        </DialogActions>
    </Dialog>

    <Grid flex sx={{padding: "20px", width: "100%", maxWidth: 1200, margin: "0 auto"}}>
      <IOChat baseUrl={IO_AI_URL} user={user} token={token} />
    </Grid>
  </>);
};

export default App;