import { createTheme } from "@mui/material";

const IOGreen = '#2CD787';
const IOGreenLight = '#95EBC3';
const IOGreenDark = '#147547';
const IOBlue = '#075E97';
const IOBlueLight = '#57A7DB';
const IOBlueDark = '#075E97';

const IOGray = '#F3F6F7';

const ioTheme = createTheme({
  palette: {
    primary: {
      main: IOBlue,
      light: IOBlueLight,
      dark: IOBlueDark,
    },
    secondary: {
      main: IOGreen,
      light: IOGreenLight,
      dark: IOGreenDark,
    },
    invert: {
      main: IOGray,
      background: "#262A2D"
    }
  }}
)

export default ioTheme;
