import React from "react";
import Button from '@mui/material/Button';

export const AvatarUrl = (user) => {
    const userInitials = user.given_name ? [user.given_name, user.family_name].map((p) => (
        p.slice(0,1)
      )).join('') : 'U';

      return `https://cdn.auth0.com/avatars/${userInitials.toLowerCase()}.png`
}

export const ResetHistory = ({baseUrl, token}) => {
    return (
        <Button color="inherit" disableRipple={true}
          style={{ backgroundColor: 'transparent' }}
          onClick={async (event) => {
            event.stopPropagation();
            const response = await fetch(`${baseUrl}/chat_history/?clear=true`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });
            if(response.ok) {
                console.log('reset chat_history')
                window.location.reload();
            }
          }}
        >
          Reset History
        </Button>
      );
}